import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Image from "gatsby-image";

import Layout from "../components/Layout";
import { Stack, Box } from "@chakra-ui/core";
import { Section } from "../components/Section";

export const PageTemplate = ({ html, image }) => (
  <>
    <Section color="backgroundWhite">
      <Stack
        width={["full", "full", "3xl", "4xl"]}
        marginBottom={["12", "12", "16s", "20"]}
        paddingLeft={["5", "6", "0", "0"]}
        paddingRight={["5", "6", "0", "0"]}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing="10"
      >
        <Box width={["3xs", "3xs", "3xs", "3xs"]} overflow="hidden">
          {image && <Image fluid={image.childImageSharp.fluid} />}
        </Box>
        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Stack>
    </Section>
  </>
);

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const {
    date,
    header_title,
    header_description,
    title,
    excerpt,
    img_path,
    subtitle,
  } = frontmatter;

  return (
    <Layout
      seoTitle={header_title}
      seoDescription={header_description}
      hideFooter={true}
    >
      <PageTemplate
        html={html}
        date={date}
        title={title}
        excerpt={excerpt}
        subtitle={subtitle}
        image={img_path}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query ImagePortfolioPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        header_title
        header_description
        title
        excerpt
        subtitle
        img_path {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
